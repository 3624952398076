import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const Container = styled.div`
  min-width: 300px;
  display: flex;

  @media (max-width: 1200px) {
    min-width: 300px;
  }

  @media (max-width: 1000px) {
    min-width: 100px;
  }
`;

export const CommandButton = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const Key = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  background-color: ${color.neutral[50]};
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;
export const CommandKey = styled(Key)``;
export const Plus = styled.span`
  margin: 0 2px;
  font-size: 14px;
  font-weight: normal;
  color: ${color.neutral[50]};
`;
