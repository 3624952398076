import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { IncomeSubject, IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import Heading from '../../../atoms/Heading';
import PageTop from '../../../molecules/Page/PageTop';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import { parseDate } from '../../../../helpers/date';
import Resources from '../../../organisms/Resources/Resources';
import Person from '../../../molecules/Person/Person';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import PaymentsCard from '../../../organisms/PaymentsShowCard/PaymentsCard';
import PositionsCard from '../../../organisms/PositionsShowCard/PositionsCard';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import moneyAsString from '../../../../helpers/moneyAsString';
import useApi from '../../../../hooks/useApi';
import { color } from '../../../../styles/Variables';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import useModal from '../../../../hooks/useModal';
import DeleteIcon from '../../../../Icons/delete.icon';
import ContractorSingleDetail from '../../../molecules/SingleDetail/ContractorSingleDetail/ContractorSingleDetail';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import BetterChip from '../../../atoms/Chips/BetterChip';
import Text from '../../../atoms/Text';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';
import DateString from '../../../atoms/DateString/DateString';
import SendInvoiceCard from './SendInvoiceCard';

type Props = {
  income: IncomeType;
};

const IncomeShow: FunctionComponent<Props> = ({ income }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { can } = React.useContext(AuthContext);
  const { post, del } = useApi();
  const { showModal, hideModal } = useModal();
  const ctx = useEntityContext();
  const { isOpen, closeSidebar } = useContext(SidebarContext);
  const [resourcesReloadKey, setResourcesReloadKey] = useState(Math.random());

  const cloneIncome = useCallback(() => {
    const cloned = { ...income, id: undefined };
    navigate('/finances/incomes/incomes/create', {
      state: { cloned: cloned },
    });
  }, [income]);

  const pageHeading = useMemo(() => {
    return (
      <Grid $simple $align={'center'} spacing={2}>
        <Heading>
          Income no. <Colored>{income.number}</Colored>
          {income.owner && `(${income.owner.name})`}
        </Heading>
        {income.internal && <BetterChip>Internal</BetterChip>}
        {income.kind && <BetterChip color={'primary'}>{income.kind}</BetterChip>}
      </Grid>
    );
  }, [income]);

  const generatePdf = () => {
    return post('/api/resources/generate_document', {
      subject: 'Finances::Income',
      subjectId: income.id.toString(),
    }).then(() => {
      ctx.reload();
      setResourcesReloadKey(Math.random());
    });
  };

  const duplicatePdf = () => {
    return post(`/api/incomes/${income.id}/duplicate`, {
      subject: 'Finances::Income',
      subjectId: income.id.toString(),
    }).then((response) => {
      ctx.reload();
      setResourcesReloadKey(Math.random());
    });
  };

  const deleteIncome = () => {
    closeSidebar();
    del(`${income['@id']}`).then(() => navigate('/finances/incomes/incomes'));
    hideModal();
  };

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete income no. ${income.number}?`,
      body: <span>Are you sure you want to delete this income?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={deleteIncome} />
        </>
      ),
    });
  }, []);

  const PageActions = () => {
    return (
      <>
        <Helmet>
          <title>{`Income ${income.number} | F.CAPE`}</title>
        </Helmet>
        <GridItem $desktop={'auto'}>
          <HistoryCard subject={IncomeSubject} subjectId={income.id} itemCreatedAt={income.createdAt} itemCreatedBy={income.createdBy} />
        </GridItem>
        {can('FINANCES.INCOMES.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button
              $text={'Edit'}
              kind={'primary'}
              type={'submit'}
              $icon={<EditIcon />}
              onClick={() => {
                navigate(pathname.replace('show', 'edit'), { replace: true });
                closeSidebar();
              }}
            />
          </GridItem>
        )}
        {can('FINANCES.INCOMES.CAN_MANAGE') && income.status === 'accepted' && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Generate pdf'} kind={'ghost'} onClick={() => generatePdf()} />
          </GridItem>
        )}{' '}
        {can('FINANCES.INCOMES.CAN_MANAGE') && income.status === 'accepted' && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Issue a duplicate'} kind={'ghost'} onClick={() => duplicatePdf()} />
          </GridItem>
        )}
        {can('FINANCES.INCOMES.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Clone'} kind={'ghost'} onClick={() => cloneIncome()} />
          </GridItem>
        )}
      </>
    );
  };

  return (
    <>
      <Grid spacing={4} style={{ marginBottom: '2rem' }}>
        <GridItem $desktop={12}>
          <PageTop heading={pageHeading} rightColumn={<PageActions />} isPdfOpen={isOpen} showBackButton />
        </GridItem>

        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Basic info'} />
              <Grid spacing={4}>
                <SingleDetail $desktop={4} title={'Number'}>
                  {income.number}
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Owner'}>
                  {income.owner ? <Person person={income.owner} size={2.4} /> : 'No owner'}
                </SingleDetail>
                <ContractorSingleDetail contractor={income.contractor} $desktop={8} />
                <GridItem $desktop={4} />
                <SingleDetail $desktop={4} title={'Location'}>
                  {income.branch.shortName}
                </SingleDetail>
                <SingleDetail title={'Company'} $desktop={8}>
                  {income.financialCompany && income.financialCompany.name}
                </SingleDetail>
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                <SingleDetail title={'Net value'} $desktop={4} type={'money'}>
                  {moneyAsString(income.amountNet)}
                </SingleDetail>
                <SingleDetail title={'Gross value'} $desktop={4} type={'money'}>
                  {moneyAsString(income.amountGross)}
                </SingleDetail>
                {income.amountNet.currency !== income.originalAmountNet.currency && (
                  <>
                    <SingleDetail title={'Exchange rate'} $desktop={4}>
                      {income.exchangeRate}
                    </SingleDetail>
                    <SingleDetail title={'Net value'} $desktop={4} type={'money'}>
                      {moneyAsString(income.originalAmountNet)}
                    </SingleDetail>
                    <SingleDetail title={'Gross value'} $desktop={4} type={'money'}>
                      {moneyAsString(income.originalAmountGross)}
                    </SingleDetail>
                  </>
                )}
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                <SingleDetail $desktop={4} title={'Issue date'} type={'date'}>
                  {parseDate(income.issuedAt)}
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Service end date'} type={'date'}>
                  {parseDate(income.postedAt)}
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Payment due'} type={'date'}>
                  {parseDate(income.paymentDue)}
                </SingleDetail>
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                <SingleDetail $desktop={4} title={'EU invoice'}>
                  {income.europeanDocument ? 'Yes' : 'No'}
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Internal comment'}>
                  {income.internalComment}
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Public comment'}>
                  {income.publicComment}
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <GridItem $desktop={12}>
              <PaymentsCard subject={income} currency={income.currency} payments={income.payments} amountGross={income.originalAmountGross} />
            </GridItem>
          </GridItem>
        </FinanceColumn>
        {isOpen && <GridItem $desktop={12} />}
        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Documents'} />
              <Grid spacing={4}>
                <Resources
                  subject={IncomeSubject}
                  subjectId={income.id}
                  disableCreation
                  maxWidth
                  noResourcesText={'No documents'}
                  reloadToken={resourcesReloadKey}
                />
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <PositionsCard positions={income.positions} />
          </GridItem>
          {income.subincomes && income.subincomes.length > 0 && (
            <GridItem $desktop={12}>
              <Card>
                <CardHeading heading={'Assignments'} />
                <Grid spacing={2}>
                  {income.subincomes.map((subincome, idx) => (
                    <GridItem $desktop={12} key={idx}>
                      <Grid $justifyContent={'space-between'}>
                        <GridItem>
                          {subincome.project ? (
                            <Text bold size={'xl'}>
                              <Colored>#{subincome.project.id}</Colored>
                              {subincome.project.name}
                            </Text>
                          ) : (
                            <Colored color={color.semantic.error[80]}>Invalid project assignment</Colored>
                          )}
                        </GridItem>
                        <GridItem>
                          <Text bold color={'grey'}>
                            {moneyAsString(subincome.originalAmountNet)}
                          </Text>
                        </GridItem>
                      </Grid>
                    </GridItem>
                  ))}
                </Grid>
              </Card>
            </GridItem>
          )}
          <GridItem $desktop={12}>
            <SendInvoiceCard income={income} />
          </GridItem>
        </FinanceColumn>
      </Grid>
      {can('FINANCES.CAN_DELETE') && (
        <Grid style={{ margin: '2rem 0 6rem' }}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Delete'} kind={'link'} type={'button'} onClick={handleDelete} style={{ padding: 0, color: color.semantic.error[100] }} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default IncomeShow;
