import React, { FunctionComponent } from 'react';
import { GridItem } from '../../../../atoms/Grid/Grid';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { CountriesTypeDict } from '../../../../../@dicts/CountriesTypeDict';
import StaticSelectInput from '../../../../molecules/Form/SelectInput/StaticSelectInput';

const AddressForm: FunctionComponent = () => {
  return (
    <>
      <GridItem $desktop={6}>
        <TextInput name={'address.street'} label={'Street'} placeholder={'Warszawska 9'} />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'address.postCode'} label={'Post code'} placeholder={'XX-XXX'} />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'address.city'} label={'City'} placeholder={'Warsaw'} />
      </GridItem>
      <GridItem $desktop={6}>
        <StaticSelectInput name={'address.country'} label={'Country'} options={CountriesTypeDict} />
      </GridItem>
    </>
  );
};

export default AddressForm;
