import React, { FunctionComponent } from 'react';
import Table from '../../Table/Table';
import subcostsListTableProperties from './SubcostsListTable.properties';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';
import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import ContractorOption from '../../../molecules/Finances/ContractorOption';

type Props = {
  globalFilters?: any;
};

const SubcostsListTable: FunctionComponent<Props> = ({ globalFilters }) => {
  return (
    <Table
      url={'/subcosts'}
      context={'/api/subcosts'}
      properties={subcostsListTableProperties}
      globalFilters={globalFilters}
      filters={[
        {
          ...FrequentFilters._search,
          placeholder: 'Search by id, document number, vendor',
          gridProps: {
            $desktop: 3,
          },
        },
        {
          type: 'custom',
          name: 'postedAt',
          label: 'Posting date',
          options: {
            component: DateSelectFilter,
          },
          gridProps: {
            $desktop: 4,
          },
        },
        {
          type: 'select',
          name: 'contractor.id',
          label: 'Vendor',
          options: {
            baseUrl: '/api/contractors',
            optionComponent: ContractorOption,
          },
          gridProps: {
            $desktop: 3,
          },
        },
      ]}
      itemLink={(item) => `/finances/costs/costs/${item.cost.id}/show`}
    />
  );
};

export default SubcostsListTable;
