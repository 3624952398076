import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import PlusIcon from '../../../../Icons/Plus.icon';
import TablePage from '../../../organisms/TablePage/TablePage';
import { properties } from './CostsList.properites';
import { filters } from './CostsList.filters';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import CostListExtendedRow from '../../../organisms/CostListExtendedRow/CostListExtendedRow';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import ExportBottom from './components/ExportBottom';

const CostsList: FunctionComponent = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const { can } = React.useContext(AuthContext);
  const [selectedCosts, setSelectedCosts] = useState<CostType[]>([]);
  const handleCreate = useCallback(() => {
    navigate(`${pathname}/create`);
  }, []);

  const CreateAction = () => {
    return <Button $text={'New cost'} kind={'primary'} onClick={() => handleCreate()} $icon={<PlusIcon />} />;
  };

  return (
    <>
      <TablePage
        url={'/costs'}
        context={'/api/costs|Costs'}
        onSelect={(list) => {
          setSelectedCosts(list);
        }}
        headingRight={<>{can('FINANCES.COSTS.CAN_MANAGE') && <CreateAction />}</>}
        defaultFilters={{
          postedAt: new Date().getFullYear(),
        }}
        extendedRow={CostListExtendedRow}
        properties={properties}
        filters={filters}
        globalFilters={{
          _archived: 0,
        }}
        heading={'Costs'}
        onCreate={handleCreate}
        itemLink={(item) => `${pathname}/${item.id}/show`}
        exportUrl={'/api/costs/export'}
      />
      <ExportBottom costs={selectedCosts} />
    </>
  );
};

export default CostsList;
