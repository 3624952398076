import { TableFiltersType } from '../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../@types/Table/FrequentFilters';
import { DictValue } from '../../../@types/Dictionary/DictValue';
import { EstimateType } from '../../../@types/Estimate/EstimateType';
import DateSelectFilter from '../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';
import ProjectOption from '../../molecules/Projects/ProjectOption/ProjectOption';

const EstimateStatusOptions: DictValue<EstimateType['status']>[] = [
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'in_progress',
    label: 'In progress',
  },
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
];

export const filters: TableFiltersType = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by title, project, client',
  },
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    ...FrequentFilters.partner,
  },
  {
    type: 'select',
    name: 'projects.id',
    label: 'Project',
    options: {
      baseUrl: '/api/projects',
      optionComponent: ProjectOption,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: EstimateStatusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'custom',
    name: 'createdAt',
    label: 'Created at',

    options: {
      component: DateSelectFilter,
    },
    gridProps: {
      $desktop: 4,
    },
  },
  {
    type: 'employee',
    name: 'createdBy.id',
    label: 'Author',
    options: {
      group: 'organisation',
    },
    gridProps: {
      $desktop: 2,
    },
  },
];
