import { EntityType } from '../../Entity/EntityType';
import { PositionType } from '../PositionType';
import { PaymentType } from '../PaymentType';
import { SpecificationType } from '../SpecificationType';
import { ContractorType } from '../Contractor/ContractorType';
import { BranchType } from '../../Branch/BranchType';
import { BasicEmployeeType, EmployeeType } from '../../Employee/EmployeeType';
import { MoneyType } from '../../Money/MoneyType';
import { FinanceStatusType } from '../FinanceStatusType';
import { FinancialCompanyType } from '../../FinancialCompany/FinancialCompanyType';
import { SubincomeType } from './SubincomeType';

export const IncomeSubject = 'Finances::Income';

export type IncomeType = EntityType & {
  status: FinanceStatusType;
  number: string;
  internal: boolean;
  contractor: ContractorType;
  branch: BranchType;
  postedAt: Date;
  issuedAt: Date;
  paymentDue: Date;
  kind: 'project' | 'management';
  europeanDocument: boolean;
  currency: string;
  originalAmountNet: MoneyType;
  amountNet: MoneyType;
  amountGross: MoneyType;
  originalAmountGross: MoneyType;
  owner?: EmployeeType;
  internalComment: string;
  publicComment: string;
  description: string;
  positions: PositionType[];
  payments: PaymentType[];
  specification: SpecificationType;
  createdAt: string;
  createdBy: EmployeeType;
  uploadedResources?: (number | string)[];
  exchangeRate: number;
  financialCompany: FinancialCompanyType;
  liabilities?: MoneyType;
  subincomes?: SubincomeType[];
  sendingStatus: 'sent' | 'idle' | 'sending';
  sentBy: BasicEmployeeType;
  sentAt: string;
};
