import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Styled from './WallEntry.styled';
import { FeedEntryType } from '../../../../../../@types/FeedEntry/FeedEntryType';
import FeedEntryTopWrapper from '../../../../../molecules/WallEntryItem/WallEntryTopWrapper/FeedEntryTopWrapper';
import FeedEntryBottomWrapper from '../../../../../molecules/WallEntryItem/WallEntryBottomWrapper/FeedEntryBottomWrapper';
import FeedEntryBody from '../../../../../molecules/WallEntryItem/WallEntryBody/FeedEntryBody';
import FeedEntryForm from '../../../../../molecules/NewWallEntry/FeedEntryForm/FeedEntryForm';
import { useInView } from 'react-intersection-observer';
import useApi from '../../../../../../hooks/useApi';

type Props = {
  feedEntry: FeedEntryType;
  feedEntryIndex?: number;
};

const FeedEntryItem: FunctionComponent<Props> = ({ feedEntry, feedEntryIndex }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { ref, inView } = useInView();
  const { post } = useApi();

  const toggleEditing = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  useEffect(() => {
    if (feedEntry.readByUser) {
      return;
    }
    if (!inView) {
      return;
    }
    const timeout = setTimeout(() => {
      void post(`${feedEntry['@id']}/read`, {}, { hideSnack: true });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [inView, feedEntry]);

  return (
    <Styled.FeedEntryCard blueBorder={!feedEntry.readByUser} ref={ref}>
      <FeedEntryTopWrapper feedEntry={feedEntry} onEdit={toggleEditing} />
      {!isEditing ? (
        <FeedEntryBody feedEntry={feedEntry} forceShow={feedEntryIndex === 0} />
      ) : (
        <FeedEntryForm
          onChange={() => {
            setIsEditing(false);
          }}
          basePath={`${feedEntry['@id']}`}
          onCancel={() => {
            setIsEditing(false);
          }}
        />
      )}
      <FeedEntryBottomWrapper feedEntry={feedEntry} feedEntryIndex={feedEntryIndex} />
    </Styled.FeedEntryCard>
  );
};

export default FeedEntryItem;
