import React, { FunctionComponent } from 'react';
import Table from '../../../../organisms/Table/Table';
import { properties } from '../../../Estimates/EstimatesListScreen.properties';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Button from '../../../../atoms/Button/Button';
import PlusIcon from '../../../../../Icons/Plus.icon';

const EstimatesView: FunctionComponent = () => {
  const { entity } = useEntityContext();
  const navigate = useNavigate();
  return (
    <Grid>
      <GridItem $desktop={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          $text={'Create cost estimate'}
          $icon={<PlusIcon />}
          onClick={() => {
            navigate('/finances/estimates/create', { state: { project: entity } });
          }}
        />
      </GridItem>
      <GridItem $desktop={12}>
        <Table
          globalFilters={{
            'projects.id': entity.id,
          }}
          url={'/estimates'}
          context="/api/estimates|Project"
          properties={properties}
          onItemClick={(item) => {
            navigate(`/finances/estimates/${item.id}/show`);
          }}
        />
      </GridItem>
    </Grid>
  );
};
export default EstimatesView;
