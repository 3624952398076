import React, { CSSProperties, FunctionComponent, useCallback, useState } from 'react';
import * as Styled from './WallEntryBottomWrapper.styled';
import CommentIcon from '../../../../Icons/Comment.icon';
import Comments from '../../../organisms/Comments/Comments';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';
import Reaction from '../../../atoms/Reaction/Reaction';
import IriHelper from '../../../../helpers/iri-helper';
import HeartButton from '../../HeartButton/HeartButton';
import useApi from 'hooks/useApi';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import { useNavigate } from 'react-router-dom';

type Props = {
  feedEntry: FeedEntryType;
  areCommentsVisible?: boolean;
  style?: CSSProperties;
  context?: string;
  feedEntryIndex?: number;
};

const FeedEntryBottomWrapper: FunctionComponent<Props> = ({ feedEntry, areCommentsVisible = false, style = {}, context, feedEntryIndex }) => {
  const [commentsVisible, setCommentsVisible] = useState<boolean>(feedEntryIndex === 0 ? true : areCommentsVisible);
  const [reactions, setReactions] = useState<{
    reactedByUser: boolean;
    likes: number;
  }>({
    reactedByUser: feedEntry.reactedByUser,
    likes: feedEntry.reactions.heart,
  });
  const { post } = useApi();
  const navigate = useNavigate();

  const handleToggleComments = useCallback(() => {
    setCommentsVisible((prev) => !prev);
  }, []);

  const handleToggleLike = useCallback(() => {
    post(`/feed_entries/${feedEntry.id}/react`, { reactionType: 'heart' }, { hideSnack: true }).then((res: { data: FeedEntryType }) => {
      const data = res.data;
      setReactions({
        reactedByUser: data.reactedByUser,
        likes: data.reactions.heart,
      });
    });
  }, []);

  return (
    <>
      <Styled.BottomWrapper style={style} $isDashboard={context === 'dashboard'}>
        <Reaction
          reactionType={<HeartButton onClick={handleToggleLike} isFavorite={reactions.reactedByUser} iconColor={color.neutral[60]} />}
          count={reactions.likes > 0 ? reactions.likes : 0}
        />
        <Reaction
          reactionType={
            <Icon size={1.3} color={color.neutral[60]} onClick={context === 'dashboard' ? undefined : () => handleToggleComments}>
              <CommentIcon />
            </Icon>
          }
          count={feedEntry.commentsCount}
          onClick={context === 'dashboard' ? () => navigate(`/projects/feed?feedEntry_${feedEntry.id}_comments`) : handleToggleComments}
        />
      </Styled.BottomWrapper>
      <Styled.AnimatedWrapper areCommentsVisible={commentsVisible}>
        <div id={feedEntryIndex === 0 ? 'firstFeedEntryComments' : ''} />
        {commentsVisible && (
          <Comments subject={'FeedEntry'} subjectId={IriHelper.iriToId(feedEntry['@id']) as string} header={false} localStorageKey={feedEntry['@id']} />
        )}
      </Styled.AnimatedWrapper>
    </>
  );
};

export default FeedEntryBottomWrapper;
