import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text';

const PersonContactForm: FunctionComponent = () => {
  return (
    <>
      <GridItem $desktop={6}>
        <TextInput name={'email'} label={'E-mail'} required placeholder={'jan@acme.comp'} />
        <Text size={'xs'}>This email will be used to send invoices via Income module.</Text>
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'phone'} label={'Phone number'} required placeholder={'+48 888 999 000'} />
      </GridItem>
    </>
  );
};

export default PersonContactForm;
