import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { ContractSubject, ContractType } from '../../../../@types/Finances/Contracts/ContractType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTop from '../../../molecules/Page/PageTop';
import Chip from '../../../atoms/Chips/Chip';
import Heading from '../../../atoms/Heading';
import { color } from '../../../../styles/Variables';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import Person from '../../../molecules/Person/Person';
import { parseDate } from '../../../../helpers/date';
import { ContractAppliesDict } from '../../../../@dicts/Contract/ContractAppliesDict';
import { ContractTypeDict } from '../../../../@dicts/Contract/ContractTypeDict';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import Resources from '../../../organisms/Resources/Resources';
import useApi from '../../../../hooks/useApi';
import DeleteIcon from '../../../../Icons/delete.icon';
import useModal from '../../../../hooks/useModal';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import ContractorSingleDetail from '../../../molecules/SingleDetail/ContractorSingleDetail/ContractorSingleDetail';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  contract: ContractType;
  onChange: () => void;
};

const ContractShow: FunctionComponent<Props> = ({ contract, onChange }) => {
  const navigate = useNavigate();
  const { del } = useApi();
  const location = useLocation();
  const { can } = React.useContext(AuthContext);
  const { showModal, hideModal } = useModal();
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const pageHeading = useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
          <Heading color={color.primary['60']}>#{contract.id} </Heading>
          <Heading>{`${contract.title}`}</Heading>
        </div>
        {contract.confidential && (
          <Chip $color={'light'} $bgColor={color.dark}>
            {'Confidential'}
          </Chip>
        )}
      </div>
    );
  }, [contract]);

  const deleteContract = () => {
    closeSidebar();
    del(`${contract['@id']}`).then(() => navigate('/finances/contracts/'));
    hideModal();
  };

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete "${contract.title}"?`,
      body: <span>Are you sure you want to delete this contract?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={deleteContract} />
        </>
      ),
    });
  }, []);

  const PageActions = () => {
    return (
      <>
        <GridItem $desktop={'auto'}>
          <HistoryCard subject={ContractSubject} subjectId={contract.subjectId} itemCreatedAt={contract.createdAt} itemCreatedBy={contract.createdBy} />
        </GridItem>
        {can('FINANCES.CONTRACTS.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button
              $text={'Edit'}
              kind={'primary'}
              type={'submit'}
              $icon={<EditIcon />}
              onClick={() => {
                navigate(location.pathname.replace('show', 'edit'));
                closeSidebar();
              }}
            />
          </GridItem>
        )}
      </>
    );
  };

  return (
    <>
      <Grid spacing={4} style={{ marginBottom: '2rem' }}>
        <Helmet>
          <title>{`Contract ${contract.title} | F.CAPE`}</title>
        </Helmet>
        <GridItem $desktop={12}>
          <PageTop heading={pageHeading} rightColumn={<PageActions />} paddingBottom={'0'} isPdfOpen={isOpen} showBackButton />
        </GridItem>

        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Basic info'} />
              <Grid spacing={4}>
                <ContractorSingleDetail contractor={contract.contractor} $desktop={6} />
                <SingleDetail $desktop={6} title={'Location'}>
                  {contract.branch?.shortName ?? 'N/A'}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Title'}>
                  {contract.title}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Owner'}>
                  {contract.owner ? <Person person={contract.owner} size={2.4} /> : 'N/A'}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Company'}>
                  {contract.company?.name}
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Description'}>
                  {contract.description ?? '-'}
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Details'} />
              <Grid spacing={4}>
                <SingleDetail $desktop={6} title={'Contract number'}>
                  {contract.number}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Parent contract number'}>
                  {contract.parentContract ? contract.parentContract.number : 'N/A'}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Contract date'} type={'date'}>
                  {parseDate(contract.date)}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Contract signing date'} type={'date'}>
                  {contract.dateSigned ? parseDate(contract.dateSigned) : 'N/A'}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Binder reference'}>
                  {contract.binderReference}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Contract type'} dictionary={ContractTypeDict}>
                  {contract.type}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Contract applies'} dictionary={ContractAppliesDict}>
                  {contract.applies}
                </SingleDetail>
                {contract.detailsPartner && (
                  <SingleDetail $desktop={12} title={'Partner'}>
                    {contract.detailsPartner.name}
                  </SingleDetail>
                )}
                {contract.detailsProject && (
                  <SingleDetail $desktop={12} title={'Project'}>
                    #{contract.detailsProject.id} {contract.detailsProject.name}
                  </SingleDetail>
                )}
                {contract.detailsPurchaseOrder && (
                  <SingleDetail $desktop={12} title={'Purchase Order'}>
                    #{contract.detailsPurchaseOrder.number} {contract.detailsPurchaseOrder.title}
                  </SingleDetail>
                )}
                {contract.detailsEmployee && (
                  <SingleDetail $desktop={12} title={'Employee'}>
                    {contract.detailsEmployee.name}
                  </SingleDetail>
                )}
              </Grid>
            </Card>
          </GridItem>
        </FinanceColumn>
        {isOpen && <GridItem $desktop={12} />}
        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Uploaded documents'} />
              <Grid spacing={4}>
                <Resources subject={ContractSubject} subjectId={contract.id} maxWidth noResourcesText={'No documents'} />
              </Grid>
            </Card>
          </GridItem>
        </FinanceColumn>
      </Grid>
      {can('FINANCES.CAN_DELETE') && (
        <Grid style={{ margin: '2rem 0 6rem' }}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Delete'} kind={'link'} type={'button'} onClick={handleDelete} style={{ padding: 0, color: color.semantic.error[100] }} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default ContractShow;
