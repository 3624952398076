import React, { ButtonHTMLAttributes, FunctionComponent, LegacyRef, MutableRefObject, ReactHTMLElement, RefObject, useCallback, useRef, useState } from 'react';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import BetterChip from '../../../atoms/Chips/BetterChip';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Text from '../../../atoms/Text';
import Person from '../../../molecules/Person/Person';
import DateString from '../../../atoms/DateString/DateString';
import Button from '../../../atoms/Button/Button';
import { Card } from '../../../atoms/Card/Card';
import Form from '../../../molecules/Form/Form';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import useApi from '../../../../hooks/useApi';
import { color, fontWeight } from '../../../../styles/Variables';
import useModal from '../../../../hooks/useModal';
import { ModalFooter } from '../../../molecules/Modal/Modal';
import { PiPaperPlane, PiPaperPlaneTiltBold } from 'react-icons/pi';

type Props = {
  income: IncomeType;
};

const SendInvoiceCard: FunctionComponent<Props> = ({ income }) => {
  const [waiting, setWaiting] = useState(false);
  const { post } = useApi();
  const button = useRef() as MutableRefObject<HTMLButtonElement>;
  const { showModal, hideModal } = useModal();
  const handleSend = useCallback((data: any) => {
    setWaiting(true);
    hideModal();
    post(`${income['@id']}/send`, data, { successMessage: 'Invoice sent successfully' })
      .catch(() => {
        // pass
      })
      .finally(() => {
        hideModal();
        setWaiting(false);
      });
  }, []);

  const doShowModal = useCallback(() => {
    showModal({
      title: 'Confirm invoice sending',
      body: 'Are you sure you want to send this invoice?',
      footer: (
        <ModalFooter>
          <Button
            kind={'ghost'}
            $text={'Cancel'}
            onClick={() => {
              hideModal();
            }}
          />
          <Button
            $text={'Send invoice'}
            onClick={() => {
              button.current?.click();
            }}
          />
        </ModalFooter>
      ),
    });
  }, [button]);

  return (
    <Card>
      <CardHeading heading={'Send invoice'} rightElement={<BetterChip>{income.sendingStatus}</BetterChip>} />

      <Grid spacing={2}>
        {income.sentAt && (
          <GridItem $desktop={12}>
            <div
              style={{
                borderRadius: 8,
                background: color.neutral[20],
                padding: '10px 8px',
                display: 'flex',
                alignItems: 'center',
                gap: 5,
              }}
            >
              <PiPaperPlaneTiltBold />
              <Text style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                Invoice was sent by{' '}
                <div style={{ marginBottom: 1 }}>
                  <Person person={income.sentBy} />
                </div>{' '}
                at <DateString input={income.sentAt} time />
              </Text>
            </div>
          </GridItem>
        )}
        <GridItem $desktop={12}>
          <Form onSubmit={handleSend}>
            <Grid spacing={2}>
              <GridItem $desktop={12}>
                {income.contractor.email ? (
                  <Text>
                    Invoice will be send to <span style={{ fontWeight: fontWeight.bold }}>{income.contractor.email}</span>
                  </Text>
                ) : (
                  <Text>There is no email provided in {income.contractor.name}. You have to specify it manually</Text>
                )}
              </GridItem>
              <GridItem $desktop={12}>
                <TextInput
                  name={'additionalRecipients'}
                  label={'Additional recipients (separated with comma ",")'}
                  placeholder="alfa@example.com, beta@example.com"
                />
              </GridItem>
              <GridItem $desktop={12}>
                <button ref={button} style={{ display: 'none' }} type="submit" />
                <Button
                  waiting={waiting}
                  type="button"
                  onClick={doShowModal}
                  $text={income.sentAt ? 'Resend' : 'Send invoice'}
                  kind={income.sentAt ? 'ghost' : 'primary'}
                />
              </GridItem>
            </Grid>
          </Form>
        </GridItem>
      </Grid>
    </Card>
  );
};
export default SendInvoiceCard;
