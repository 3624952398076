import { useCallback } from 'react';
import api from '../services/api';
import SnackContainer from '../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { AxiosResponse } from 'axios';

type ConfigType = {
  successMessage?: string;
  hideSnack?: boolean;
};

type UseApiResponse = {
  post: <T = any>(url: string, data?: any, config?: ConfigType) => Promise<AxiosResponse<T>>;
  put: <T = any>(url: string, data: any, hideSnack?: boolean) => Promise<AxiosResponse<T>>;
  del: (url: string) => Promise<any>;
};

const prepareData = (data: any) => {
  for (const key in data) {
    //@ts-ignore
    if (data[key] === '' && key === 'id') {
      //@ts-ignore
      delete data[key];
    }
    if (data[key] && typeof data[key] === 'object' && data[key].hasOwnProperty('@id')) {
      data[key] = data[key]['@id'];
    }
    if (Array.isArray(data[key])) {
      for (const arrayIdx in data[key]) {
        if (data[key][arrayIdx] === '') {
          data[key].splice(Number(arrayIdx), 1);
        } else if (typeof data[key][arrayIdx] === 'object') {
          data[key][arrayIdx] = prepareData(data[key][arrayIdx]);
        }
      }
    }
  }
  return data;
};

function useApi(): UseApiResponse {
  const { showSnack } = SnackContainer.useContainer();
  const post = useCallback((url: string, data: any = {}, config: ConfigType = { hideSnack: false }) => {
    data = prepareData(data);
    return api
      .post(url, data)
      .then((response) => {
        if (!config.hideSnack) {
          showSnack({
            type: 'success',
            title: 'Success',
            message: config.successMessage ?? 'Item created successfully',
          });
        }
        return response;
      })
      .catch((e) => {
        showSnack({
          type: 'error',
          title: 'Error',
          message: e.response.data['hydra:description'],
        });
        throw e;
      });
  }, []);

  const put = useCallback((url: string, data: any, hideSnack: boolean = false) => {
    data = prepareData(data);
    console.log('test');
    return api
      .put(url, data)
      .then((response) => {
        if (!hideSnack) {
          showSnack({
            type: 'success',
            title: 'Success',
            message: 'Item saved successfully',
          });
        }
        return response;
      })
      .catch((e) => {
        showSnack({
          type: 'error',
          title: 'Error',
          message: e.response.data['hydra:description'],
        });
        throw e;
      });
  }, []);

  const del = useCallback((url: string) => {
    return api
      .delete(url)
      .then((response) => {
        showSnack({
          type: 'success',
          title: 'Success',
          message: 'Item deleted successfully',
        });
        return response;
      })
      .catch((e) => {
        showSnack({
          type: 'error',
          title: 'Error',
          message: e.response['hydra:description'],
        });
        throw e;
      });
  }, []);
  return { post, del, put };
}

export default useApi;
