import React, { FunctionComponent, useCallback } from 'react';
import { EstimateType } from '../../../../@types/Estimate/EstimateType';
import Form from '../../../molecules/Form/Form';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import EstimateFormProjects from './EstimateFormProjects';
import PageTop from '../../../molecules/Page/PageTop';
import Button from '../../../atoms/Button/Button';
import useApi from '../../../../hooks/useApi';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyInput from '../../../molecules/Form/CurrencyInput/CurrencyInput';
import EstimateFormVersion from './EstimateFormVersion';
import { ProjectType } from '../../../../@types/Project/Project';

type Props = {
  estimate?: EstimateType;
  onChange?: (estimate: EstimateType) => void;
};

const EstimateForm: FunctionComponent<Props> = ({ estimate, onChange }) => {
  const isEdit = !!estimate;
  const { put, post } = useApi();
  const navigate = useNavigate();
  const { state }: { state: { project: ProjectType } } = useLocation();

  console.log('morwa location', location);

  const handleSubmit = useCallback(
    (data: Partial<EstimateType>) => {
      if (isEdit && estimate) {
        put(`${estimate['@id']}`, data).then((response) => {
          onChange && onChange(response.data);
          navigate(`/finances/estimates/${response.data.id}/show`);
        });
      } else {
        post<EstimateType>('/api/estimates', data).then((response) => {
          onChange && onChange(response.data);
          navigate(`/finances/estimates/${response.data.id}/show`);
        });
      }
    },
    [isEdit, estimate],
  );

  return (
    <Form onSubmit={handleSubmit} defaultValues={estimate ?? (state?.project ? { projects: [state.project] } : undefined)}>
      <Grid spacing={4}>
        <GridItem $desktop={12}>
          <PageTop
            heading={isEdit ? 'Edit cost estimate' : 'Create cost estimate'}
            rightColumn={
              <>
                <Button type={'submit'} $text={'Save'} />
                <Button
                  $text={'Cancel'}
                  kind={'secondary'}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </>
            }
          />
        </GridItem>
        <GridItem $desktop={6}>
          <Grid spacing={4}>
            <GridItem $desktop={12}>
              <Card>
                <CardHeading heading={'Basic information'} />
                <Grid spacing={2}>
                  <GridItem $desktop={12}>
                    <TextInput name={'title'} label={'Title'} required />
                  </GridItem>

                  <GridItem $desktop={6}>
                    <CurrencyInput name={'currency'} required={true} />
                  </GridItem>
                  <GridItem $desktop={12}>
                    <EstimateFormProjects />
                  </GridItem>
                </Grid>
              </Card>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem $desktop={6}>
          <Card>
            <CardHeading heading={'Description'} />
            <Grid spacing={2}>
              <GridItem $desktop={12}>
                <RichTextInput name={'description'} label={'Description'} />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      </Grid>
    </Form>
  );
};
export default EstimateForm;
