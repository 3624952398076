import React, { FunctionComponent, useCallback } from 'react';
import { properties } from './PurchaseOrders.properties';
import { filters } from './PurchaseOrders.filters';
import { useNavigate } from 'react-router-dom';
import TablePage from '../../TablePage/TablePage';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import PlusIcon from '../../../../Icons/Plus.icon';

type Props = {};

const PurchaseOrdersList: FunctionComponent<Props> = () => {
  const navigate = useNavigate();

  const handleCreate = useCallback(() => {
    navigate('/finances/purchase-orders/create');
  }, []);

  return (
    <TablePage
      url={'/purchase_orders'}
      context={'/api/purchase_orders'}
      headingRight={<Button $text={'New purchase order'} kind={'primary'} onClick={() => handleCreate()} $icon={<PlusIcon />} />}
      properties={properties}
      filters={filters}
      itemLink={(item) => `/finances/purchase-orders/${item.id}/show`}
      heading={'Purchase orders'}
      globalFilters={{ _archived: 0 }}
      defaultFilters={{
        createdAt: new Date().getFullYear(),
      }}
    />
  );
};

export default PurchaseOrdersList;
