import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { IncomeSubject, IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../../hooks/useApi';
import Form from '../../../molecules/Form/Form';
import PageTop from '../../../molecules/Page/PageTop';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import DatePicker from '../../../molecules/Form/DatePicker/DatePicker';
import { SwitchInput } from '../../../molecules/Form/SwitchInput/SwitchInput';
import { TextAreaInput } from '../../../molecules/Form/TextAreaInput/TextAreaInput';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import PaymentsInput from '../../../organisms/PaymentsInput/PaymentsInput';
import PositionsInput from '../../../organisms/PositionsInput/PositionsInput';
import CurrencyInput from '../../../molecules/Form/CurrencyInput/CurrencyInput';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import api from '../../../../services/api';
import { ProjectType } from '../../../../@types/Project/Project';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import DynamicSelectInput from '../../../molecules/Form/SelectInput/DynamicSelectInput';
import useLocationQueryObject from '../../../../hooks/useLocationQueryObject';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import SubincomesInput from './components/SubincomesInput';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { ClientType } from '../../../../@types/Project/Client/ClientType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { Loader } from '../../../../legacy/CapeMorris/components';
import IriHelper from '../../../../helpers/iri-helper';
import { BranchType } from '../../../../@types/Branch/BranchType';
import { FinancialCompanyType } from '../../../../@types/FinancialCompany/FinancialCompanyType';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  income?: IncomeType;
  onChange?: () => void;
  isEdit?: boolean;
};

const IncomeForm: FunctionComponent<Props> = ({ income, onChange, isEdit = false }) => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [uploadedResources, setUploadedResources] = useState<ResourceType[]>([]);
  const navigate = useNavigate();
  const { isOpen, closeSidebar } = useContext(SidebarContext);
  const defaults: any = useLocationQueryObject({
    postedAt: new Date().toISOString().split('T')[0],
    issuedAt: new Date().toISOString().split('T')[0],
    kind: 'project',
    currency: 'PLN',
  });

  const pageHeading = useMemo(() => {
    if (!income) {
      return 'Add new income';
    } else {
      return `Edit income ${income.number} (${income.owner?.name})`;
    }
  }, [income]);

  const { post, put } = useApi();

  const handleSubmit = (formData: IncomeType) => {
    closeSidebar();
    setLoading(true);
    formData.uploadedResources = uploadedResources.map((resource) => resource.id);

    if (isEdit && income) {
      put(`${income['@id']}`, formData)
        .then((response) => {
          navigate(pathname.replace('edit', 'show'), { replace: true });
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      post('/api/incomes', formData)
        .then((response) => {
          navigate(pathname.replace('create', `${response.data.id}/show`), { replace: true });
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const PageActions = () => {
    return (
      <>
        <GridItem $desktop={'auto'}>
          <Button
            $text={loading ? 'Saving...' : 'Save income'}
            waiting={loading}
            kind={'primary'}
            type={'submit'}
            $icon={income ? <EditIcon /> : <AcceptIcon />}
          />
        </GridItem>
        <GridItem $desktop={'auto'}>
          <Button
            $text={'Cancel'}
            kind={'secondary'}
            onClick={() => {
              if (pathname.includes('create')) {
                navigate(-1);
              } else {
                navigate(pathname.replace('edit', 'show'), { replace: true });
              }
              closeSidebar();
            }}
          />
        </GridItem>
      </>
    );
  };

  return (
    <>
      {(income || defaults !== undefined) && (
        <Form onSubmit={(data) => handleSubmit(data)} defaultValues={income ?? defaults}>
          {({ watch, setValue }) => {
            const [init, setInit] = useState<boolean>(false);
            const [initKey, setInitKey] = useState(0);
            const [owner, setOwner] = useState<any>();
            const [branch, setBranch] = useState<BranchType>();
            const [financialCompany, setFinancialCompany] = useState<FinancialCompanyType>();
            const [client, setClient] = useState<ClientType | undefined>();
            const currency = watch('currency');
            const kind = watch('kind');
            const [contractor, setContractor] = useState();

            useEffect(() => {
              if (init) {
                return;
              }
              if (income) {
                setInit(true);
                return;
              }
              void Promise.all([
                api.get<ProjectType>(defaults.project).then(({ data }) => {
                  setOwner(data.leader);
                }),
                defaults.project
                  ? api
                      .get('/api/contractors', {
                        params: {
                          'client.projects.id': IriHelper.iriToId(defaults.project) ?? undefined,
                          'order[isPrimary]': 'desc',
                        },
                      })
                      .then((response) => {
                        setContractor(response.data['hydra:member'].length > 0 ? response.data['hydra:member'][0] : undefined);
                      })
                  : undefined,
                api.get('/api/branches/1').then((response) => {
                  setValue('branch', response.data);
                  setBranch(response.data);
                }),
                api.get('/api/financial_companies/1').then((response) => {
                  setValue('financialCompany', response.data);
                  setFinancialCompany(response.data);
                }),
              ]).finally(() => {
                setInit(true);
                setInitKey(Math.random());
              });
            }, [defaults?.project, init, income]);

            const handleContractorChange = (c: DictValue<ContractorType>) => {
              setClient(c?.meta?.client ?? undefined);
            };

            return (
              <Grid spacing={4}>
                <Helmet>
                  <title>{`${income ? 'Edit income' : 'Create income'} | F.CAPE`}</title>
                </Helmet>
                <GridItem $desktop={12} style={{ marginBottom: '2rem' }}>
                  <PageTop heading={pageHeading} rightColumn={<PageActions />} paddingBottom={'0'} isPdfOpen={isOpen} />
                </GridItem>

                <FinanceColumn>
                  <GridItem $desktop={12}>
                    <Card style={{ position: 'relative' }}>
                      {!init && <Loader />}
                      <CardHeading heading={'Basic info'} />
                      <Grid spacing={4}>
                        <GridItem $desktop={6}>
                          <TextInput name={'number'} label={'Number'} placeholder={'Income number'} required />
                        </GridItem>
                        <GridItem $desktop={6}>
                          <StaticSelectInput
                            options={[
                              { value: 'management', label: 'Management' },
                              { value: 'project', label: 'Project' },
                            ]}
                            name={'kind'}
                            label={'Kind'}
                            placeholder={'Invoice kind'}
                            required
                          />
                        </GridItem>
                        <GridItem $desktop={6}>
                          <DynamicSelectInput
                            name={'owner'}
                            defaultValue={owner}
                            label={'Owner'}
                            baseUrl={'/api/employees/'}
                            baseUrlParams={{ status: ['active'] }}
                            key={initKey}
                          />
                        </GridItem>
                        <GridItem $desktop={6}>
                          <DynamicSelectInput
                            name={'contractor'}
                            label={'Contractor'}
                            baseUrl={'/api/contractors'}
                            required
                            defaultValue={contractor}
                            onChange={handleContractorChange}
                            key={initKey}
                            optionComponent={ContractorOption}
                          />
                        </GridItem>
                        <GridItem $desktop={4}>
                          <DynamicSelectInput
                            name={'branch'}
                            label={'Location'}
                            defaultValue={branch}
                            optionLabel={'shortName'}
                            baseUrl={'/api/branches/'}
                            key={initKey}
                            required
                          />
                        </GridItem>
                        <GridItem $desktop={4}>
                          <CurrencyInput name={'currency'} required />
                        </GridItem>
                        <GridItem $desktop={4} />
                        <GridItem $desktop={4}>
                          <DatePicker name={'issuedAt'} label={'Issue date'} placeholder={'Choose date'} required />
                        </GridItem>
                        <GridItem $desktop={4}>
                          <DatePicker name={'postedAt'} label={'Service end date'} placeholder={'Choose date'} required />
                        </GridItem>
                        <GridItem $desktop={4}>
                          <DatePicker name={'paymentDue'} label={'Payment due'} placeholder={'Choose date'} required />
                        </GridItem>
                        <GridItem $desktop={12}>
                          <DynamicSelectInput
                            defaultValue={financialCompany}
                            name={'financialCompany'}
                            label={'Company'}
                            baseUrl={'/api/financial_companies/'}
                            required
                            key={initKey}
                          />
                        </GridItem>
                        <GridItem $desktop={12}>
                          <TextAreaInput name={'internalComment'} label={'Internal comment'} placeholder={'Internal comment'} rows={3} />
                        </GridItem>
                        <GridItem $desktop={12}>
                          <TextAreaInput name={'publicComment'} label={'Public comment (printed)'} placeholder={'Public comment'} rows={3} />
                        </GridItem>
                        <GridItem $desktop={4}>
                          <SwitchInput name={'europeanDocument'} label={'EU invoice'} />
                        </GridItem>
                        {kind === 'management' && (
                          <GridItem $desktop={4}>
                            <SwitchInput name={'internal'} label={'Internal document'} />
                          </GridItem>
                        )}
                      </Grid>
                    </Card>
                  </GridItem>
                  <GridItem $desktop={12}>
                    <Card>
                      <Grid spacing={4}>
                        <PaymentsInput name={'payments'} currency={currency} />
                      </Grid>
                    </Card>
                  </GridItem>
                </FinanceColumn>
                {isOpen && <GridItem $desktop={12} />}
                <FinanceColumn>
                  <GridItem $desktop={12}>
                    <Card>
                      <CardHeading heading={'Documents'} />
                      <Grid spacing={4}>
                        <ResourceDropzone
                          subject={IncomeSubject}
                          subjectId={income ? income.id : undefined}
                          context={'finances'}
                          onChange={(data) => setUploadedResources(data)}
                        />
                      </Grid>
                    </Card>
                  </GridItem>
                  <GridItem $desktop={12}>
                    <PositionsInput />
                  </GridItem>
                  {kind === 'project' && (
                    <GridItem $desktop={12}>
                      <SubincomesInput client={client} key={initKey} />
                    </GridItem>
                  )}
                </FinanceColumn>
              </Grid>
            );
          }}
        </Form>
      )}
    </>
  );
};

export default IncomeForm;
