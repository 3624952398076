import React, { CSSProperties, FunctionComponent } from 'react';
import * as Components from './Search.styled';
import AsyncSelect from 'react-select/async';
import api from '../../../../services/api';
import { AxiosResponse } from 'axios';
import { ProjectType } from '../../../../@types/Project/Project';
import SearchOption from './SearchOption';
import { searchSelectStyles } from './SearchSelectStyles';
import SearchControl from './SearchControl';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { PiCommandBold } from 'react-icons/pi';
import Commander from '../../../../components/organisms/Commander/Commander';

interface OwnProps {
  style?: CSSProperties;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<{
  'hydra:member': ProjectType[];
}>;

type MappedResponseType = {
  label: string;
  value: string;
  meta: ProjectType;
};

type CallbackType = (projects: MappedResponseType[]) => void;

const Search: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const [value] = React.useState<null>(null);
  const loadOptions = React.useCallback((input: string, callback: CallbackType) => {
    void api
      .get('/projects', {
        params: {
          _search: input,
          archived: false,
        },
      })
      .then((response: ApiResponse) => {
        callback(
          response.data['hydra:member'].map((project) => ({
            label: project.name,
            value: String(project.id),
            meta: project,
          })),
        );
      });
  }, []);

  const handleChange = React.useCallback(
    (
      value: OnChangeValue<
        {
          value: string;
          label: string;
          meta: ProjectType;
        },
        false
      >,
    ) => {
      if (value) {
        navigate(`/projects/projects/${value.meta.id}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Components.Container id={'projects-search-input'} style={props.style}>
      <Commander />
      <AsyncSelect
        value={value}
        onChange={handleChange}
        placeholder="Search projects"
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        styles={searchSelectStyles}
        components={{
          Control: SearchControl,
          Option: SearchOption,
          IndicatorSeparator: null,
          DropdownIndicator: null,
        }}
      />
    </Components.Container>
  );
};

export default Search;
