import React, { useMemo } from 'react';
import { LoaderContainer, LoaderWrapper } from './loader.styled';
import Narwhal from '../../../../Icons/Narwhal';
import Pumpkin from '../../../../Icons/Pumpkin';

export const Loader: React.FC<{ background?: boolean }> = ({ background = true }) => {
  const isHalloween = useMemo(() => {
    const date = new Date();
    if (date.getMonth() === 9 && (date.getDate() === 31 || date.getDate() === 30)) return true;
  }, []);
  const jumpKeyframes = {
    translateY: [-30, 0],
    duration: 500,
  };

  return (
    <LoaderWrapper $background={background}>
      <LoaderContainer>
        {!isHalloween && <Narwhal />}
        {isHalloween && <Pumpkin />}
      </LoaderContainer>
    </LoaderWrapper>
  );
};
