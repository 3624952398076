import { CSSObjectWithLabel } from 'react-select';

export const searchSelectStyles = {
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    border: 'none',
    paddingLeft: 0,
    cursor: 'pointer',
    boxShadow: 'none',
  }),
  option: (styles: CSSObjectWithLabel) => ({
    ...styles,
    cursor: 'pointer',
  }),
};
