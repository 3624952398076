import React, { FunctionComponent } from 'react';
import { ESTIMATE_ACCEPT_PROOF_SUBJECT, ESTIMATE_SUBJECT, ESTIMATE_VERSION_SUBJECT, EstimateType } from '../../../../@types/Estimate/EstimateType';
import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import { Helmet } from 'react-helmet';
import Heading from '../../../atoms/Heading';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import PageTop from '../../../molecules/Page/PageTop';
import EstimateStatusTag from '../../../molecules/Estimate/EstimateStatusTag';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import RichText from '../../../atoms/RichText/RichText';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import { color, fontWeight } from '../../../../styles/Variables';
import EstimateDetailsVersion from './EstimateDetailsVersion';
import Person from '../../../molecules/Person/Person';
import DateString from '../../../atoms/DateString/DateString';
import EstimateStatusButton from './EstimateStatusButton';
import Button from '../../../atoms/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { PiLinkBold, PiPlusBold } from 'react-icons/pi';
import Resources from '../../../organisms/Resources/Resources';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import styled from 'styled-components';

type Props = {
  estimate: EstimateType;
};

const EstimateDetailsInner: FunctionComponent<Props> = ({ estimate }) => {
  const navigate = useNavigate();
  return (
    <Grid spacing={4} style={{ marginBottom: '2rem' }}>
      <Helmet>
        <title>{`Estimate ${estimate.title} | F.CAPE`}</title>
      </Helmet>
      <PageTop
        heading={
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
              <Heading>
                <Colored>#{estimate.id}</Colored>
                &nbsp;{estimate.title}
              </Heading>
            </div>
            <EstimateStatusTag status={estimate.status} />
          </div>
        }
        rightColumn={
          <>
            <CopyLinkButton
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.origin}/finances/estimates/${estimate.id}/show`);
              }}
            >
              <PiLinkBold />
            </CopyLinkButton>
            <HistoryCard
              subject={[ESTIMATE_SUBJECT, ESTIMATE_ACCEPT_PROOF_SUBJECT]}
              subjectId={estimate.id}
              itemCreatedAt={estimate.createdAt}
              itemCreatedBy={estimate.createdBy}
            />
            {estimate.status !== 'accepted' && <Button $text="Edit" onClick={() => navigate(`/finances/estimates/${estimate.id}/edit`)} />}
            <EstimateStatusButton estimate={estimate} />
          </>
        }
        showBackButton
      />
      <GridItem $desktop={6}>
        <Grid spacing={4}>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Details'} />
              <Grid spacing={2}>
                <GridItem $desktop={9}>
                  <SingleDetail title="Title">{estimate.title}</SingleDetail>
                </GridItem>
                <GridItem $desktop={3}>
                  <SingleDetail title="Currency">{estimate.currency}</SingleDetail>
                </GridItem>
                <GridItem $desktop={12}>
                  <SingleDetail title="Partner">{estimate.client?.name}</SingleDetail>
                </GridItem>
                <GridItem $desktop={12}>
                  <SingleDetail title="Projects">
                    {estimate.projects.map((p) => (
                      <Link to={`/projects/projects/${p.id}/overview`} key={p.id}>
                        <Colored style={{ fontWeight: fontWeight.bold }}>#{p.id}</Colored>
                        {p.name}
                        <br />
                      </Link>
                    ))}
                  </SingleDetail>
                </GridItem>
                <GridItem $desktop={9}>
                  <SingleDetail title={'Created by'}>
                    <Person person={estimate.createdBy} />
                  </SingleDetail>
                </GridItem>
                <GridItem $desktop={3}>
                  <SingleDetail title={'Created at'}>
                    <DateString input={estimate.createdAt} />
                  </SingleDetail>
                </GridItem>
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Current versions attachments'} />
              <Resources disableCreation subject={ESTIMATE_VERSION_SUBJECT} subjectId={estimate.currentVersion.id} />
            </Card>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={6}>
        <Grid spacing={4}>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Description'} />
              <RichText content={estimate.description} />
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Acceptance proof'} />
              <Resources
                disableCreation={estimate.status === 'accepted'}
                subject={ESTIMATE_ACCEPT_PROOF_SUBJECT}
                subjectId={estimate.id}
                context={'finances'}
              />
            </Card>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading
            heading={'Versions'}
            rightElement={
              <>
                {estimate.status !== 'accepted' && (
                  <Button
                    small
                    $icon={<PiPlusBold />}
                    $text="Create version"
                    onClick={() => {
                      navigate(`/finances/estimate-versions/create`, { state: { estimate: estimate } });
                    }}
                  />
                )}
              </>
            }
          />
          <Grid spacing={2}>
            {estimate.versions
              .sort((a) => (a.status === 'current' ? -1 : a.status === 'alternative' ? 0 : 1))
              .map((version) => (
                <GridItem key={version.id} $desktop={12}>
                  <EstimateDetailsVersion editable={estimate.status !== 'accepted'} version={version} key={version['@id']} />
                </GridItem>
              ))}
          </Grid>
        </Card>
      </GridItem>
    </Grid>
  );
};

const CopyLinkButton = styled.div`
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: ${color.primary[60]};
  }
`;
export default EstimateDetailsInner;
