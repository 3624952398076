import React, { FunctionComponent, useCallback, useContext } from 'react';
import { ContractorSubject, ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditBankModal from './EditBankModal';
import useModal from '../../../../hooks/useModal';
import Button from '../../../atoms/Button/Button';
import DateString from '../../../atoms/DateString/DateString';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import PageTop from '../../../molecules/Page/PageTop';
import EditIcon from '../../../../Icons/edit.icon';
import Text from '../../../atoms/Text/Text';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import BankAccountShow from './BankAccountShow';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import Tooltip from '../../../molecules/Tooltip/Tooltip';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import Heading from '../../../atoms/Heading';
import Resources from '../../../organisms/Resources/Resources';
import FinanceStatus from '../../../molecules/Statuses/FinanceStatus/FinanceStatus';
import ContractorTypeTag from '../../../molecules/Finances/ContractorTypeTag/ContractorTypeTag';
import ApprovalCard from '../../../organisms/RequestCard/ApprovalCard';
import useApi from '../../../../hooks/useApi';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import { CountriesTypeDict } from '../../../../@dicts/CountriesTypeDict';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import DeleteIcon from '../../../../Icons/delete.icon';
import { properties as PurchaseOrderProperties } from '../../../organisms/Tables/PurchaseOrdersList/PurchaseOrders.properties';
import { filters as PurchaseOrderFilters } from '../../../organisms/Tables/PurchaseOrdersList/PurchaseOrders.filters';
import Table from '../../../organisms/Table/Table';
import { contractsListProperties as ContractsProperties } from '../../Contracts/ContractsList/ContractsList.properties';
import { contractListFilters as ContractFilters } from '../../Contracts/ContractsList/ContractsList.filters';
import CostListExtendedRow from '../../../organisms/CostListExtendedRow/CostListExtendedRow';
import { properties as CostsProperties } from '../../Costs/CostsList/CostsList.properites';
import { filters as CostsFilters } from '../../Costs/CostsList/CostsList.filters';
import Chip from '../../../atoms/Chips/Chip';
import { ContractTypeDict } from '../../../../@dicts/Contract/ContractTypeDict';
import translateDictionary from '../../../../helpers/translateDictionary';
import { incomeTableProperties } from '../../Income/IncomeList/IncomeList.properties';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  contractor: ContractorType;
};

const ContractorShow: FunctionComponent<Props> = ({ contractor }) => {
  const { can } = useContext(AuthContext);
  const ctx = useEntityContext();
  const location = useLocation();
  const { showModal, hideModal } = useModal();
  const { post, del } = useApi();
  const navigate = useNavigate();
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const handleEditBank = useCallback(() => {
    showModal({
      title: 'Edit bank details',
      body: <EditBankModal entity={contractor} onSuccess={ctx.reload} />,
    });
  }, [contractor]);

  const deleteContractor = () => {
    closeSidebar();
    del(`${contractor['@id']}`).then(() => navigate('/finances/vendors/'));
    hideModal();
  };

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete "${contractor.name ?? contractor.firstName + ' ' + contractor.lastName}"?`,
      body: <span>Are you sure you want to delete this contractor?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={deleteContractor} />
        </>
      ),
    });
  }, []);

  const handleApprovalRequest = useCallback(() => {
    return post('/contractor_approval_requests', {
      contractor: contractor['@id'],
    }).then(() => {
      ctx.reload();
    });
  }, []);

  const EditBankAction = () => {
    if (can('FINANCES.CONTRACTORS.CAN_CHANGE_BANK')) {
      return (
        <Icon onClick={handleEditBank}>
          <EditIcon />
        </Icon>
      );
    } else {
      return (
        <Tooltip
          content={
            <Text size={'xs'} color={'light'}>
              You do not have permission to edit bank details.
            </Text>
          }
          trigger={
            <Icon disabled={true}>
              <EditIcon color={color.neutral[50]} />
            </Icon>
          }
          arrowPosition={'rightCenter'}
        />
      );
    }
  };

  return (
    <>
      <Grid spacing={4} style={{ marginBottom: '2rem' }}>
        <Helmet>
          <title>Vendor {contractor.name} | F.CAPE</title>
        </Helmet>
        <GridItem $desktop={12}>
          <PageTop
            heading={
              <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.3rem',
                  }}
                >
                  <Heading color={color.primary['60']}>#{contractor.id} </Heading>
                  <Heading>{`${contractor.name}`}</Heading>
                </div>
                <ContractorTypeTag contractorType={contractor.type} />
              </div>
            }
            rightColumn={
              <>
                <GridItem $desktop={'auto'}>
                  <HistoryCard
                    itemCreatedBy={contractor.createdBy}
                    subject={ContractorSubject}
                    subjectId={contractor.subjectId}
                    itemCreatedAt={contractor.createdAt}
                  />
                </GridItem>
                {can('FINANCES.CONTRACTORS.CAN_MANAGE') && (
                  <GridItem $desktop={'auto'}>
                    <Button
                      $text={'Edit'}
                      kind={'primary'}
                      type={'submit'}
                      $icon={<EditIcon />}
                      onClick={() => navigate(location.pathname.replace('show', 'edit'))}
                    />
                  </GridItem>
                )}
              </>
            }
            paddingBottom={'0'}
            isPdfOpen={isOpen}
            showBackButton
          />
        </GridItem>

        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Basic info'} />
              <Grid spacing={4}>
                {contractor.type === 'company' && (
                  <>
                    <SingleDetail $desktop={6} title={'Company name'}>
                      {contractor.name}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Representative'}>
                      {contractor.representative}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Is outside UE?'}>
                      {contractor.outsideEuropeanUnion ? 'Yes' : 'No'}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Tax number'}>
                      {contractor.taxNumber ?? 'N/A'}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Partner'}>
                      {contractor.client ? contractor.client.name : 'N/A'}
                    </SingleDetail>
                  </>
                )}
                {contractor.type === 'person' && (
                  <>
                    <SingleDetail $desktop={6} title={'First name'}>
                      {contractor.firstName}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Last name'}>
                      {contractor.lastName}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Birthday'}>
                      {contractor.birthDate ? <DateString input={contractor.birthDate} /> : 'N/A'}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'PESEL'}>
                      {contractor.pesel}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Tax office'}>
                      {contractor.taxOffice}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Document type'}>
                      {contractor.documentType?.toUpperCase()}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Document number'}>
                      {contractor.documentNumber}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Document valid until'}>
                      {contractor.documentValidTo ? <DateString input={contractor.documentValidTo} /> : 'N/A'}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Partner'}>
                      {contractor.client ? contractor.client.name : 'N/A'}
                    </SingleDetail>
                  </>
                )}
                <SingleDetail $desktop={6} title={'Created at'} type={'date'}>
                  <DateString input={contractor.createdAt} time />
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Comment'}>
                  {contractor.note}
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Contracts (click to view)'}>
                  <div style={{ display: 'flex', marginTop: '0.7rem' }}>
                    {contractor.contractTags.map((contractType) => (
                      <Link style={{ marginRight: '0.6rem' }} key={contractType} to={`/finances/contracts?contractor.id=${contractor.id}&type=${contractType}`}>
                        <Chip>{translateDictionary(ContractTypeDict, contractType)}</Chip>
                      </Link>
                    ))}
                  </div>
                </SingleDetail>
              </Grid>
            </Card>
            <Card style={{ marginTop: '3rem' }}>
              <CardHeading heading={'Address'} />
              <Grid spacing={4}>
                <SingleDetail $desktop={6} title={'Street'}>
                  {contractor.address.street}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Post code'}>
                  {contractor.address.postCode}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'City'}>
                  {contractor.address.city}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Country'} dictionary={CountriesTypeDict}>
                  {contractor.address.country}
                </SingleDetail>
              </Grid>
            </Card>
            <Card style={{ marginTop: '3rem' }}>
              <CardHeading heading={'Contact'} />
              <Grid spacing={4}>
                {contractor.type === 'company' && (
                  <>
                    <SingleDetail $desktop={6} title={'First name'}>
                      {contractor.firstName}
                    </SingleDetail>
                    <SingleDetail $desktop={6} title={'Last name'}>
                      {contractor.lastName}
                    </SingleDetail>
                  </>
                )}
                <SingleDetail $desktop={6} title={'Email'}>
                  {contractor.email}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Phone'}>
                  {contractor.phone}
                </SingleDetail>
              </Grid>
            </Card>
            <Card style={{ marginTop: '3rem' }}>
              <CardHeading
                heading={'Bank account'}
                action={
                  <Grid $simple spacing={4} $align={'center'}>
                    <EditBankAction />
                    <FinanceStatus status={contractor.status} />
                  </Grid>
                }
              />
              <Grid spacing={4}>
                <BankAccountShow contractor={contractor} />
              </Grid>
            </Card>
          </GridItem>
        </FinanceColumn>
        {isOpen && <GridItem $desktop={12} />}
        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Uploaded documents'} />
              <Grid spacing={4}>
                <Resources subject={ContractorSubject} subjectId={contractor.id} maxWidth noResourcesText={'No documents'} />
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            {contractor.bank?.number ? (
              <ApprovalCard
                requestable={contractor}
                onApprovalRequest={handleApprovalRequest}
                requests={contractor.requests.length !== 0 ? contractor.requests : []}
              />
            ) : (
              <Card>
                <CardHeading heading={'Approval request'} />
                <Text>Bank account details are required to request approval.</Text>
              </Card>
            )}
          </GridItem>
        </FinanceColumn>

        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Purchase orders'} />
            <Grid spacing={4}>
              <Table
                url={'/purchase_orders'}
                context={'/api/purchase_orders'}
                properties={PurchaseOrderProperties}
                filters={PurchaseOrderFilters}
                itemLink={(item) => `/finances/purchase-orders/${item.id}/show`}
                globalFilters={{
                  'contractor.id': contractor.id,
                  _archived: 0,
                }}
                defaultFilters={{
                  'contractor.id': contractor.id,
                  createdAt: new Date().getFullYear(),
                }}
              />
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Contracts'} />
            <Grid spacing={4}>
              <Table
                context={'/api/contracts'}
                itemLink={(item) => `/finances/contracts/contracts/${item.id}/show`}
                url={'/contracts'}
                properties={ContractsProperties}
                filters={ContractFilters}
                globalFilters={{
                  'contractor.id': contractor.id,
                  _archived: 0,
                }}
              />
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Incomes'} />
            <Grid spacing={4}>
              <Table
                context={'/api/incomes'}
                itemLink={(item) => `/finances/incomes/incomes/${item.id}/show`}
                url={'/incomes'}
                defaultFilters={{
                  postedAt: new Date().getFullYear(),
                }}
                properties={incomeTableProperties}
                globalFilters={{
                  'contractor.id': contractor.id,
                  _archived: 0,
                }}
              />
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Costs'} />
            <Grid spacing={4}>
              <Table
                url={'/costs'}
                context={'/api/costs'}
                defaultFilters={{
                  postedAt: new Date().getFullYear(),
                }}
                extendedRow={CostListExtendedRow}
                properties={CostsProperties}
                filters={CostsFilters}
                globalFilters={{
                  'contractor.id': contractor.id,
                  _archived: 0,
                }}
                itemLink={(item) => `/finances/costs/costs/${item.id}/show`}
              />
            </Grid>
          </Card>
        </GridItem>
      </Grid>
      {can('FINANCES.CAN_DELETE') && (
        <Grid style={{ margin: '2rem 0 6rem' }}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Delete'} kind={'link'} type={'button'} onClick={handleDelete} style={{ padding: 0, color: color.semantic.error[100] }} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default ContractorShow;
